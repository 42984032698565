import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, convertFromRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { stateToMarkdown } from "draft-js-export-markdown";
import tw from "twin.macro";
import styled from "styled-components";

// Стили кнопок и контейнера
const Container = tw.div`p-4 flex flex-col items-center justify-center`;
const Button = tw.button`px-4 py-2 bg-blue-500 text-white rounded-lg mt-4 mb-5 hover:bg-blue-700`;
const TextContainer = tw.div`mt-4 p-4 border border-gray-300 rounded-lg bg-white w-full max-w-4xl`;

// Добавленные стили для улучшения видимости редактора
const EditorWrapper = tw.div`p-4 border-2 border-blue-500 rounded-lg bg-gray-100 shadow-lg w-full max-w-4xl`;
const EditorContainer = styled.div`
  ${tw`min-h-[300px] p-4 bg-white rounded-md border-2 border-gray-300`}
  font-size: 18px;
  line-height: 1.6;
`;

// Функция для извлечения markdown текста из editorState
const getMarkdownFromEditor = (editorState) => {
   const contentState = editorState.getCurrentContent();
   return stateToMarkdown(contentState);
};

const RichTextEditor = () => {
   const localStorageKey = "richTextContent";

   // Загружаем данные из localStorage или создаем пустой текст
   const loadContentFromStorage = () => {
      const savedData = localStorage.getItem(localStorageKey);
      if (savedData) {
         const contentState = convertFromRaw(JSON.parse(savedData));
         return EditorState.createWithContent(contentState);
      } else {
         const defaultText = `Вітаємо, шановна няне!

Ми дуже цінуємо вашу готовність допомогти у догляді за нашою дитиною і хочемо переконатися, що ви поділяєте наші цінності та підходите нашій родині ❤️.

Просимо вас пройти короткий тест, який допоможе нам оцінити вашу кваліфікацію та готовність працювати з дітьми 👶. Це важливий крок, щоб стати частиною нашої сім'ї!

Перейдіть за посиланням: https://test.stimul.ua/${localStorage.getItem('orderReference')}

Дякуємо вам за ваше бажання піклуватися про нашу дитину!`;
         const contentState = ContentState.createFromText(defaultText);
         return EditorState.createWithContent(contentState);
      }
   };

   const [editorState, setEditorState] = useState(() => loadContentFromStorage());
   const [copied, setCopied] = useState(false);

   useEffect(() => {
      // Сохранение данных в localStorage при изменении редактора
      const contentState = editorState.getCurrentContent();
      localStorage.setItem(localStorageKey, JSON.stringify(convertToRaw(contentState)));
   }, [editorState]);

   const handleEditorChange = (newState) => {
      setEditorState(newState);
      setCopied(false); // Убираем сообщение о копировании при изменении текста
   };

   return (
      <Container>

         <TextContainer>{getMarkdownFromEditor(editorState)}</TextContainer>

         {/* Кнопка для копирования текста */}
         <CopyToClipboard text={getMarkdownFromEditor(editorState)} onCopy={() => setCopied(true)}>
            <Button  >{copied ? "Скопійовано!" : "Копіювати текст"}</Button>
         </CopyToClipboard>
         <h1>Відредагуйте текст на свій смак:</h1>

         {/* Обертка для редактора */}
         <EditorWrapper>
            {/* Поле редактирования */}
            <EditorContainer>
               <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  toolbar={{
                     options: ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link", "emoji", "history"],
                  }}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
               />
            </EditorContainer>
         </EditorWrapper>

         {/* Текст в Markdown формате для копирования */}

      </Container>
   );
};

export default RichTextEditor;
